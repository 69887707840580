import { createSpaceReducer, SpaceActionType, Space } from "pubnub-redux";
import { AppActions } from "main/AppActions";
import { ObjectsCustom } from "pubnub-redux/dist/foundations/ObjectsCustom";
import { SpacesByIdState } from "pubnub-redux/dist/features/space/SpaceReducer";
// import showNotification from "foundations/utilities/showNotification";
import { Dispatch } from "redux";
import { SpaceEvent } from "pubnub";
import { SpaceSuccess } from "pubnub-redux/dist/features/space/SpaceActions";
// import { SpaceListenerPayload } from "pubnub-redux/dist/features/space/SpaceActions";

export interface TypingIndicator extends Space {
  type: SpaceEvent;
}

export type SpaceCreatedEnvelope = Required<
  Pick<Space, "id" | "name" | "created" | "custom">
> & {
  event: SpaceEvent;
};

const spaceReducer = createSpaceReducer<SpaceCreatedEnvelope>();

const defaultState = { byId: {} }; // correct initial state?

export const NotificationSpaceCreatedReducer = (
  state: SpacesByIdState<SpaceCreatedEnvelope>,
  action: AppActions
): SpacesByIdState<SpaceCreatedEnvelope> => {
  switch (action.type) {
    case SpaceActionType.SPACE_CREATED:
      return spaceReducer(state, action);
    default:
      return state || defaultState;
  }
};

// export const createNotificationSpaceCreatedListener = <ReceivedSpace extends Space<ObjectsCustom>>(
export const createNotificationSpaceCreatedListener = <
  ReceivedSpace extends Space<ObjectsCustom>
>(
  dispatch: Dispatch<AppActions>
) => ({
  // todo add correct types
  space: (payload: SpaceSuccess<SpaceCreatedEnvelope>) => {
    // todo add correct types
    console.log(payload.response.data);
    switch (payload.response.data.event.channel) {
      // case "create":
      //   showNotification("Test message", "GeneralEnquiry"); // ThunkAction
      //   //dispatch(notificationSpaceCreated(payload)); // notify app that space was created. Not needed. Should remove
      //   dispatch(spaceCreated(payload));
      //   break;
      default:
        break;
    }
  }
});
