import HashIds from "hashids";
import getTimestamp from "./getTimestamp";

export default (timetoken: string) => {
  const hashids = new HashIds(
    `${timetoken}`,
    12,
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
  );
  const epoch = getTimestamp();
  const hash = hashids.encode(epoch);

  return hash;
};
