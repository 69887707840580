import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getCurrentConversationId } from "features/currentConversation/currentConversationModel";
import { getConversationsByUserId } from "../joinedConversationModel";
import { MembershipHash } from "../joinedConversationModel";
import {
  ConversationsIndexedById,
  getConversationsById
} from "features/conversations/conversationModel";
import { focusOnConversation } from "features/currentConversation/currentConversationModel";
import { getLoggedInUserId } from "features/authentication/authenticationModel";
import { AddIcon } from "foundations/components/icons/AddIcon";
import { ConversationItem } from "../ConversationItem";
import {
  Wrapper,
  Title,
  AddButton,
  ConversationList
} from "./MyConversations.style";
import { fetchSpaces, fetchMembers } from "pubnub-redux";
import { getCurrentConversationMembers } from "features/conversationMembers/ConversationMembers/ConversationMembers";
import { UserFragment } from "features/conversationMembers/MemberDescription/MemberDescription";
import {
  currentConversationViewDisplayed,
  joinConversationViewDisplayed,
  saveConversationHistoryViewDisplayed,
  saveConversationClipboardHistoryViewDisplayed
} from "features/layout/LayoutActions";
import { joinConversation } from "../joinConversationCommand";
import { getUsersById } from "features/users/userModel";
// import { leaveConversation } from "../leaveConversationCommand";

export interface ConversationFragment {
  id: string;
  name: string;
  custom: any;
}

export const getJoinedConversations = createSelector(
  [getConversationsById, getLoggedInUserId, getConversationsByUserId],
  (
    conversations: ConversationsIndexedById,
    userId: string,
    userConversations: MembershipHash
  ): ConversationFragment[] => {
    return userConversations[userId]
      ? userConversations[userId]
          .filter(el => el.hasOwnProperty("id"))
          .map(conversation => {
            return {
              id: conversation.id,
              name:
                conversations[conversation.id].name ??
                conversations[conversation.id].custom?.brokerName, // conversations[conversation.id] undefined
              custom: conversations[conversation.id].custom
            };
          })
      : [
          {
            id: "unknown",
            name: "unknown",
            custom: {}
          }
        ];
  }
);

const MyConversations = () => {
  const currentUserId = useSelector(getLoggedInUserId);
  const conversationsById = useSelector(getConversationsById);
  const usersById = useSelector(getUsersById);
  const user = usersById[currentUserId];
  const conversations: ConversationFragment[] = useSelector(
    getJoinedConversations
  );
  console.log(JSON.stringify(conversations));
  const currentConversationId: string = useSelector(getCurrentConversationId);
  const members: UserFragment[] = useSelector(getCurrentConversationMembers);
  const dispatch = useDispatch();
  const openOverlay = () => {
    dispatch(
      fetchSpaces({
        include: {
          customFields: true,
          totalCount: true
        }
      })
    );
    dispatch(joinConversationViewDisplayed());
  };

  if (conversationsById === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Title>
        My Chat Sessions
        <AddButton onClick={openOverlay}>
          <AddIcon title="Accept chat session" />
        </AddButton>
      </Title>
      <ConversationList>
        {conversations.map(conversation => (
          <ConversationItem
            id={conversation.id}
            name={conversation.name}
            onLeave={() => {
              dispatch(focusOnConversation(conversation.id));

              if (conversation.id.includes("CaseEnquiry")) {
                dispatch(saveConversationHistoryViewDisplayed());
              } else if (conversation.id.includes("GeneralEnquiry")) {
                dispatch(saveConversationClipboardHistoryViewDisplayed());
              }
            }}
            onJoin={() => {
              const username = user.name;
              dispatch(
                joinConversation(currentUserId, conversation.id, username)
              );
            }}
            conversation={conversation}
            selected={conversation.id === currentConversationId}
            key={conversation.id}
            unreadMessageCount={0}
            onClick={() => {
              dispatch(focusOnConversation(conversation.id));
              dispatch(currentConversationViewDisplayed());

              if (members.length === 0) {
                dispatch(
                  fetchMembers({
                    spaceId: conversation.id,
                    include: {
                      userFields: true,
                      customUserFields: true,
                      totalCount: true
                    }
                  })
                );
              }
            }}
          ></ConversationItem>
        ))}
      </ConversationList>
    </Wrapper>
  );
};

export { MyConversations };
