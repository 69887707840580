export default (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const tokenData = localStorage.getItem("api-token");

    if (tokenData) {
      const apiToken = JSON.parse(tokenData) as ApiToken;

      if (Date.now() < apiToken.exp * 1000) {
        resolve(apiToken.token);
        return;
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/token`;

    const request = new Request(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8"
      },
      cache: "no-cache",
      body: JSON.stringify(process.env.REACT_APP_API_SECRET_KEY)
    });

    fetch(request)
      .then(r => {
        r.json().then((v: ApiToken) => {
          localStorage.setItem("api-token", JSON.stringify(v));
          resolve(v.token);
        });
      })
      .catch(e => reject(e));
  });
};

interface ApiToken {
  token: string;
  nbf: number;
  exp: number;
}
