import React from "react";

interface LeaveIconProps {
  title: string;
  selected: boolean;
}

export const LeaveIcon = ({ title, selected }: LeaveIconProps) => (
  <svg width={19} height={15}>
    <title>{title}</title>
    <g id="layer1">
      <path
        d="m 1.22708,1.0510198 c 0.4109702,0.2904862 0.8571177,0.5276934 1.2963409,0.771681 0.6005652,0.3336127 0.5135622,0.2864227 1.1201949,0.6335332 1.0995592,0.6231481 2.1816657,1.2826477 3.1894691,2.0476895 0.2246162,0.1705102 0.4417185,0.3506984 0.6625777,0.5260477 1.3662059,1.1066543 2.6112524,2.3509046 3.8212134,3.6234591 0.69305,0.7465872 1.37601,1.5052197 2.005284,2.3069297 0.25178,0.320774 0.505359,0.665489 0.749139,0.992933 0.360224,0.513559 0.741822,1.013819 1.086313,1.53839 0.109568,0.166845 0.216635,0.351608 0.318712,0.524009 0.152132,0.267766 0.306736,0.532996 0.444135,0.808413 0.05215,0.135939 0.132958,0.257535 0.182696,0.394226 0.0067,0.02464 0.0134,0.04928 0.02011,0.07392 0,0 -0.815636,0.442013 -0.815636,0.442013 v 0 c -0.0055,-0.02224 -0.01092,-0.04448 -0.01638,-0.06672 -0.04642,-0.134561 -0.129828,-0.251547 -0.174988,-0.386979 -0.129669,-0.277008 -0.275617,-0.54501 -0.4197,-0.814985 C 14.593564,14.27996 14.508291,14.121126 14.39429,13.939455 14.061719,13.409473 13.686876,12.906191 13.333957,12.390002 13.127243,12.106262 12.810077,11.6671 12.59853,11.392863 11.97828,10.58881 11.301641,9.8291142 10.617849,9.0791832 9.4149781,7.8039344 8.1611699,6.5740133 6.7984235,5.4684194 6.5778849,5.2943112 6.3612018,5.1152049 6.1368077,4.9460949 5.1255228,4.183961 4.0326167,3.5416091 2.9321149,2.918976 2.1101678,2.4550107 1.2677608,2.0288492 0.45234583,1.5536452 c 0,0 0.77473417,-0.5026254 0.77473417,-0.5026254 z"
        id="path107"
      />
      <path
        d="M 0.50837416,15.453774 C 0.87775522,14.761233 1.2430299,14.066401 1.606982,13.370962 1.9784378,12.615795 2.4162409,11.89909 2.9601924,11.255082 3.3797589,10.758338 3.5768931,10.586542 4.0464618,10.124559 5.0297405,9.1957978 6.0964239,8.3631141 7.1587745,7.5283496 8.1946324,6.7144023 7.7252611,7.0776109 8.7495816,6.2988823 10.067363,5.3160621 11.359277,4.3003976 12.63392,3.26253 c 0.520983,-0.3831137 0.958508,-0.8630903 1.454523,-1.2747149 0.03049,-0.021876 0.06097,-0.043753 0.09146,-0.065629 0,0 0.858896,-0.3355278 0.858896,-0.3355278 v 0 c -0.03307,0.019999 -0.06613,0.039997 -0.0992,0.059996 -0.52997,0.4022436 -0.97155,0.912013 -1.515746,1.2983377 C 12.131737,3.9851996 10.828305,5.010765 9.4973087,6.0010892 8.4813066,6.7686419 8.9203123,6.4298967 7.8956724,7.2333643 6.8418399,8.0597232 5.7847567,8.8853033 4.8122032,9.8081976 4.3538482,10.257364 4.145358,10.440403 3.7347069,10.923438 c -0.5394488,0.634536 -0.9756677,1.341006 -1.3358447,2.090745 -0.3533575,0.684657 -0.7088785,1.3682 -1.0525713,2.05776 0,0 -0.83791674,0.381831 -0.83791674,0.381831 z"
        id="path111"
      />
    </g>
  </svg>
);
