import Pubnub from "pubnub";
import DefaultConversation from "../../config/defaultConversation.json";

export default async (pubnub: Pubnub) => {
  try {
    const spaceIds: string[] = [];
    const twentyFourHours = 1000 * 60 * 60 * 24;
    const now = Date.now();
    let next = null;

    while (true) {
      const response: any = await pubnub.getSpaces({
        page: { next: next }
      });

      if (response.data.length === 0) {
        console.log("total: ", response.totalCount);
        break;
      }

      response.data.forEach((space: any) => {
        const lastUpdated = now - new Date(space.updated).valueOf();
        console.log(space.id, space.updated);
        if (
          space.id !== DefaultConversation.conversationId &&
          lastUpdated > twentyFourHours
        ) {
          spaceIds.push(space.id);
        }
      });

      next = response.next;
    }

    console.log("Deleting space IDs: ", spaceIds);

    spaceIds.forEach(spaceId => {
      pubnub.deleteSpace(spaceId, (s, r) => {
        if (s.error) {
          console.error("could not delete space ", spaceId);
        }
        console.log("space deleted ", spaceId);
      });
    });
  } catch (error) {
    console.error(error);
  }
};
