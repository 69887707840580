import { ThunkAction } from "main/storeTypes";

export const createChatUser = (
  id: string,
  name: string
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    return new Promise((resolve: any, reject: any) => {
      context.pubnub.api.createUser(
        {
          id: id,
          name: name
          // profileUrl: "~/webchat/logo192.png",
          // email: payload.email,
          // custom: {
          //   email: payload.email
          // }
        },
        (status: any, response: any) => {
          if (!status.error) {
            resolve();
          } else if (status.statusCode === 409) {
            resolve();
          } else if (status.statusCode === 400) {
            resolve();
          } else {
            reject();
          }
          console.log(JSON.stringify(status));
        }
      );
    });
  };
};
