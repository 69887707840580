import { ThunkAction } from "main/storeTypes";
import { Custom } from "main/global";
import convertTimestampToDatetime from "foundations/utilities/convertTimestampToDatetime";
import moment from "moment";
import getApiToken from "foundations/utilities/getApiToken";

export const saveChatHistoryToNotes = (
  userId: string,
  conversationId: string
): ThunkAction<Promise<void>> => {
  return (_dispatch, getState) => {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(
        () => {
          getApiToken()
            .then(token => {
              const {
                messages,
                conversations,
                conversationMembers
              } = getState();

              const custom: Custom =
                conversations.conversations.byId[conversationId]?.custom;
              const msgs = messages.byId[conversationId];

              const members = conversationMembers.byId[conversationId];

              const messageHistory = msgs?.map((value, _index, _array) => {
                const member = members.find(
                  m => m.id === value.message.senderId
                );
                const t = value.message.text;
                const s = member?.user?.name ?? value.message.senderId;
                const d = convertTimestampToDatetime(
                  value.timetoken.toString()
                );

                return `[${d}] ${s}: ${t}`;
              });

              let notes = {
                summary: `Chat History (with ${custom.brokerName})`,
                note: messageHistory?.join("\n")
              };

              let applicationNotes = {
                applicationId: Number(custom.applicationId),
                userId: userId,
                note: JSON.stringify(notes.note),
                dt: moment().format("YYYY-MM-DDTHH:mm:ss"),
                title: `${notes.summary}: ${custom?.applicationName} ${custom?.contextId}`
              };

              let applicationNotesString = JSON.stringify(applicationNotes);

              const url = `${process.env.REACT_APP_API_URL}/api/ApplicationNotes`;

              const request = new Request(url, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json; charset=UTF-8"
                },
                cache: "no-cache",
                body: applicationNotesString
              });

              fetch(request);
            })
            .catch(e => reject(e));
        },
        (status: any) => {
          if (!status.error) {
            resolve();
          } else {
            if (status.statusCode === 409) {
              //skip duplicate users ie that already exists
              resolve();
            } else {
              reject();
            }
          }
        },
        2000
      );
    });
  };
};
