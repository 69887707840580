// TODO: subscribe to channelGroup with group type in payload
// The Notification permission may only be requested in a top-level document or same-origin iframe.
// show in subscribe callback

let activeNotification: Notification;

export default (message: any, channel: string, header: string) => {
  if (!("Notification" in window)) {
    return;
  }

  const notify = () => {
    if (activeNotification) {
      activeNotification.close();
    }

    activeNotification = new window.Notification(header, {
      body: message,
      data: channel,
      icon: "favicon.png"
    });
  };

  if (Notification.permission === "granted") {
    notify();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        notify();
      }
    });
  }
};
