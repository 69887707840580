import { ThunkAction } from "main/storeTypes";
import {
  focusOnConversation,
  getCurrentConversationId
} from "features/currentConversation/currentConversationModel";
import { sendMessage as sendPubnubMessage } from "pubnub-redux";
import { TextMessage, MessageType } from "features/messages/messageModel";
import { joinChatSpace } from "features/conversations/joinChatSpaceCommand";

/**
 * Join a conversation.
 * The membership in the conversation will be stored.
 * The channel for the converstation will be subscribed to to receive messages.
 * The new conversation will be made the selected conversation
 */
export const joinConversation = (
  userId: string,
  conversationId: string,
  userName: string
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    return dispatch(joinChatSpace(userId, conversationId))
      .then(() => {
        setTimeout(() => {
          context.pubnub.api.subscribe({
            channels: [conversationId],
            withPresence: true
          });
        }, 100);

        dispatch(focusOnConversation(conversationId));
      })
      .then(() => {
        const state = getState();
        const message: TextMessage = {
          type: MessageType.Text,
          senderId: "UTB Owl ChatBot",
          text: `Thanks, your enquiry has been accepted by ${userName}.`
        };
        dispatch(
          sendPubnubMessage({
            channel: getCurrentConversationId(state),
            message
          })
        );
      });
  };
};
