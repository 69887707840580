import React, { useEffect, useState } from "react";
import {
  Wrapper,
  Body,
  ConversationIcon,
  Name,
  Description,
  Content,
  MetaData,
  UtbUser
} from "./ConversationDescription.style";
import { useSelector } from "react-redux";
import { fetchMembers } from "pubnub-redux";
import {
  getUsersByConversationId,
  MembershipHash
} from "features/conversationMembers/conversationMemberModel";
import { useDispatch } from "main/useDispatch";
import { AppState } from "main/storeTypes";

export interface ConversationDescriptionFragment {
  id: string;
  name: string;
  description: string;
  custom: any;
}

interface ConversationDescriptionProps {
  conversation: ConversationDescriptionFragment;
  onClick: () => void;
}

const ConversationDescription = ({
  conversation,
  onClick
}: ConversationDescriptionProps) => {
  const dispatch = useDispatch();
  const members = useSelector((state: AppState) =>
    state.conversationMembers.byId[conversation.id]?.slice(1)
  );

  useEffect(() => {
    dispatch(
      fetchMembers({ spaceId: conversation.id, include: { userFields: true } })
    );
  }, []);

  return (
    <Wrapper onClick={onClick} hasMembers={members && members.length > 0}>
      <Body>
        <ConversationIcon>#</ConversationIcon>
        <Content>
          <Name>{conversation?.name}</Name>
          <Description>{conversation?.description}</Description>
          <MetaData>{conversation?.custom?.userName}</MetaData>
          {conversation?.custom?.contextId && (
            <MetaData>
              {`${conversation?.custom?.contextId} - ${conversation?.custom?.applicationName}`}
            </MetaData>
          )}
          {members && members.length > 0 && (
            <UtbUser>
              UTB User: {members.map(m => m.user?.name).join(", ")}
            </UtbUser>
          )}
        </Content>
      </Body>
    </Wrapper>
  );
};

export { ConversationDescription };
