export const BREAKPOINT_SMALL = 600;
export const BREAKPOINT_LARGE = 1200;

export const appTheme = {
  breakpoint: {
    mediaQuery: {
      small: `(max-width: ${BREAKPOINT_SMALL}px)`,
      large: `(min-width: ${BREAKPOINT_LARGE}px)`
    }
  },
  colors: {
    yellow: "#E5B822",
    black: "#000000",
    rollover: "#F0C63B",
    white: "#FFFFFF"
  }
};
