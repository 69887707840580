import React, { useEffect } from "react";
import GlobalStyles from "main/styles/GlobalStyles";
import Normalize from "main/styles/Normalize";
import { ApplicationRouter } from "./Router";
import { appTheme } from "./Theme";
import Pubnub, { MessageEvent } from "pubnub";
import { createPubNubListener } from "pubnub-redux";
import { PubNubProvider } from "pubnub-react";
import { Provider } from "react-redux";
import { createAppStore } from "main/store";
import { ThemeProvider } from "styled-components";
import { createTypingIndicatorsListener } from "features/typingIndicator/typingIndicatorModel";
import showNotification from "foundations/utilities/showNotification";
import DefaultConversation from "config/defaultConversation.json";
import jwtDecode from "foundations/utilities/jwtDecode";
import cleanUpSpaces from "foundations/utilities/cleanUpSpaces";

const payload = jwtDecode();

const pubnub = new Pubnub({
  restore: true,
  heartbeatInterval: 0,
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY ?? "",
  secretKey: process.env.REACT_APP_PUBNUB_SECRET_KEY,
  uuid: payload.nameid
});

// Clean up spaces older than 24 hours
cleanUpSpaces(pubnub);

const store = createAppStore({
  pubnub: {
    api: pubnub
  }
});

const leaveApplication = () => {
  // This is required to show the current user leave immediately rather than
  // wating for the timeout period
  pubnub.unsubscribeAll();
};

const App = () => {
  useEffect(() => {
    // Start listening for messages and events from PubNub
    pubnub.addListener(createPubNubListener(store.dispatch));
    pubnub.addListener(createTypingIndicatorsListener(store.dispatch));
    pubnub.addListener({
      message: (payload: MessageEvent) => {
        const currentUserId = pubnub.getUUID();
        console.log(`currentUserId: ${JSON.stringify(currentUserId)}`);
        console.log(`payload.channel: ${JSON.stringify(payload.channel)}`);
        console.log(
          `payload.message.senderId: ${JSON.stringify(
            payload.message.senderId
          )}`
        );
        switch (payload.channel) {
          case DefaultConversation.conversationId: {
            console.log(`message: ${JSON.stringify(payload)}`);
            if (payload.message.senderId === "UTB Owl ChatBot") {
              showNotification(
                `${payload.message.text}`,
                payload.channel,
                "UTB Owl ChatBot"
              );
            }
            break;
          }
          default: {
            if (
              payload.message.senderId !== currentUserId &&
              payload.message.senderId !== "UTB Owl ChatBot"
            ) {
              showNotification(
                `${payload.message.text}`,
                payload.channel,
                "Broker" // TODO: name of the broker
              );
            }
            break;
          }
        }
      }
    });

    return leaveApplication;
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", leaveApplication);
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <Provider store={store}>
        <PubNubProvider client={pubnub}>
          <Normalize />
          <GlobalStyles />
          <ApplicationRouter />
        </PubNubProvider>
      </Provider>
    </ThemeProvider>
  );
};

export { App };
